<template >
    <div >
        <div class="text-gray-900 flex mb-2" >
            <div class="text-lg font-medium " >
                Auftrag: {{ orderId }}
            </div >

            <div class="flex space-x-3" >
                <div v-if="this.order?.status == 'storniert'" class="ml-16 flex items-center" >
                    <span
                        class="inline-flex items-center rounded-full bg-error-cancellation px-3 py-0.5 text-sm text-black" >
                        <ExclamationTriangleIcon class="h-5 mr-2" /> Storniert
                    </span >
                </div >
                <div v-if="this.order?.status != 'storniert' && isSent() && this.hasOverOrdering"
                     class="ml-16 flex items-center" >
                    <span
                        class="inline-flex items-center rounded-full bg-error-over-ordering px-3 py-0.5 text-sm text-black" >
                        <ExclamationTriangleIcon class="h-5 mr-2" /> Überbestellung
                    </span >
                </div >
                <div v-if="this.order?.status != 'storniert' && isSent() && this.addressValidation?.valid === false"
                     class="ml-16 flex items-center" >
                    <span
                        class="inline-flex items-center rounded-full bg-error-address px-3 py-0.5 text-sm text-black" >
                        <ExclamationTriangleIcon class="h-5 mr-2" /> Adressfehler
                    </span >
                </div >
                <div v-if="this.order?.status == 'zuweisung' && isSent()"
                     class="ml-16 flex items-center" >
                    <span class="inline-flex items-center rounded-full bg-violet-400 px-3 py-0.5 text-sm text-black" >
                        <ExclamationTriangleIcon class="h-5 mr-2" /> Zuweisung
                    </span >
                </div >
            </div >
        </div >

        <div class="flex justify-end mb-4" >
            <div class="mr-4" >
                <!--                <TaskModal align-right :tasks="[-->
                <!--                    {-->
                <!--                        label: 'Adresse ändern',-->
                <!--                        subject: `Auftrag ${orderId} - Anpassung Adresse`,-->
                <!--                    },-->
                <!--                    {-->
                <!--                        label: 'Position ändern',-->
                <!--                        subject: `Auftrag ${orderId} - Anpassung Position`,-->
                <!--                    },-->
                <!--                    {-->
                <!--                        label: 'Auftrag stornieren',-->
                <!--                        subject: `Auftrag ${orderId} - Storno`,-->
                <!--                    },-->
                <!--                    {-->
                <!--                        label: 'Auftrag teilliefern',-->
                <!--                        subject: `Auftrag ${orderId} - Teilliefern`,-->
                <!--                    },-->
                <!--                    {-->
                <!--                        label: 'Auftrag sperren',-->
                <!--                        subject: `Auftrag ${orderId} - Sperrung`,-->
                <!--                    },-->
                <!--                    {-->
                <!--                        label: 'Auftrag priorisieren',-->
                <!--                        subject: `Auftrag ${orderId} - Priorisierung`,-->
                <!--                    },-->
                <!--                ]" />-->
            </div >

            <div class="flex justify-end mb-4" >
                <div class="mr-5" >
                    <Menu as="div" class="relative inline-block text-left" >
                        <div >
                            <MenuButton
                                class="h-10 inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sky-700 border border-sky-700 shadow-sm hover:bg-gray-50" >
                                <TagIcon class="h-5 inline-block mr-1" />
                                {{ order.tag ?? 'Tag' }}
                                <ChevronDownIcon class="h-5 inline-block ml-1" ></ChevronDownIcon >
                            </MenuButton >
                        </div >

                        <transition enter-active-class="transition ease-out duration-100"
                                    enter-from-class="transform opacity-0 scale-95"
                                    enter-to-class="transform opacity-100 scale-100"
                                    leave-active-class="transition ease-in duration-75"
                                    leave-from-class="transform opacity-100 scale-100"
                                    leave-to-class="transform opacity-0 scale-95" >
                            <MenuItems
                                class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" >
                                <div class="py-1" >
                                    <MenuItem v-slot="{ active }" class="hover:bg-gray-100"
                                              :class="tag.name === order.tag ? 'bg-gray-100' : ''" v-for="tag in tags"
                                              :key="tag" >
                                        <div class="flex justify-between px-4 py-2 text-sm" >
                                            <div @click="setTag(tag)" class="flex-1 flex items-center" >
                                                <div class="h-2 w-2 rounded-full mr-2"
                                                     :style="`background-color: ${tag.color};`" ></div >
                                                {{ tag.name }}
                                            </div >
                                            <div >
                                                <XMarkIcon @click="unsetTag"
                                                           class="cursor-pointer text-gray-600 h-5 inline-block"
                                                           v-if="tag.name === order.tag" />
                                            </div >
                                        </div >
                                    </MenuItem >
                                </div >
                            </MenuItems >
                        </transition >
                    </Menu >
                </div >

                <a :href="`/api/orders/${orderId}/csv/expanded`"
                   download
                   class="flex-1 h-10 inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-primary border border-primary shadow-sm hover:bg-gray-50"
                >
                    <InboxArrowDownIcon class="h-5 inline-block mr-1" />
                    Export .csv
                </a >
            </div >
        </div >

        <div class="flex text-gray-600 text-sm space-x-10" >
            <div >Importdatum:
                <FormatDate :date="order.imported_at" />
            </div >
            <div >Externe Order-Nummer: {{ order.external_id }}</div >
        </div >

        <hr class="my-5" >

        <div class="sm:hidden mb-5" >
            <label for="tabs" class="sr-only" >Select a tab</label >
            <select id="tabs" name="tabs"
                    v-model="currentTab"
                    class="block w-full rounded-md border-gray-300 focus:border-secondary focus:ring-secondary" >
                <option v-for="tab in tabs" @change="currentTab = tab" >{{ tab }}
                </option >
            </select >
        </div >
        <div class="hidden sm:block mb-5" >
            <nav class="flex space-x-4" aria-label="Tabs" >
                <span v-for="tab in tabs"
                      :class="[tab == currentTab ? 'bg-secondary text-white' : 'text-gray-500 hover:text-gray-700', 'px-3 py-2 font-medium text-sm rounded-md cursor-pointer']"
                      :aria-current="tab == currentTab ? 'page' : undefined"
                      @click="currentTab = tab" >{{ tab }}</span >
            </nav >
        </div >

        <hr class="my-5" >

        <div v-show="currentTab == 'Positionen'" class="" >
            <v-client-table ref="positionsTable" :data="this.order?.positions" :columns="positionColumns"
                            :options="positionOptions" >
                <template v-slot:available="props" >
                    <div class="flex items-center" >
                        {{ Math.round(props.row["available"]) }}
                        <span v-if="props.row['available'] < props.row['quantity'] && isSent()"
                              class="inline-flex items-center rounded-full bg-error-over-ordering px-3 py-0.5 text-sm text-black ml-2" >
                        <ExclamationTriangleIcon class="h-5" />
                    </span >
                    </div >
                </template >
            </v-client-table >
        </div >
        <div v-show="currentTab == 'Lieferadresse'" >

            <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4" >
                <div >
                    <label for="first_name" class="block text-sm font-medium text-gray-700" >Vorname</label >
                    <div class="mt-1" >
                        <input disabled v-model="order.customer.first_name" type="text" id="first_name"
                               name="first_name"
                               autocomplete="given-name"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-indigo-500 sm:text-sm"
                               :class="addressValidation.fields.first_name ? ['!border-primary'] : []" />
                    </div >
                </div >
                <div >
                    <label for="last_name" class="block text-sm font-medium text-gray-700" >Nachname</label >
                    <div class="mt-1" >
                        <input disabled v-model="order.customer.last_name" type="text" id="last_name" name="last_name"
                               autocomplete="last-name"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                               :class="addressValidation.fields.last_name ? ['!border-primary'] : []" />
                    </div >
                </div >
                <div >
                    <label for="customer_id" class="block text-sm font-medium text-gray-700" >Kundennummer (für B2B
                        Orders)</label >
                    <div class="mt-1" >
                        <input disabled v-model="order.customer.customer_id" type="text" id="customer_id"
                               name="customer_id"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="company" class="block text-sm font-medium text-gray-700" >Firma</label >
                    <div class="mt-1" >
                        <input disabled v-model="order.customer.company" type="text" id="company" name="company"
                               autocomplete="company"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                               :class="addressValidation.fields.company ? ['!border-primary'] : []" />
                    </div >
                </div >
                <div >
                    <label for="company_suffix" class="block text-sm font-medium text-gray-700" >Firmenzusatz</label >
                    <div class="mt-1" >
                        <input disabled v-model="order.customer.company_suffix" type="text" id="company_suffix"
                               name="company_suffix"
                               autocomplete="company-suffix"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="address" class="block text-sm font-medium text-gray-700" >Straße + Hausnummer</label >
                    <div class="mt-1" >
                        <input disabled v-model="order.customer.address" type="text" id="address" name="address"
                               autocomplete="address"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                               :class="addressValidation.fields.address ? ['!border-primary'] : []" />
                    </div >
                </div >
                <div >
                    <label for="addressSuffix" class="block text-sm font-medium text-gray-700" >Adresszusatz</label >
                    <div class="mt-1" >
                        <input disabled v-model="order.customer.address_supplement" type="text" id="addressSuffix"
                               name="addressSuffix" autocomplete="address"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="city" class="block text-sm font-medium text-gray-700" >Stadt</label >
                    <div class="mt-1" >
                        <input disabled v-model="order.customer.city" type="text" id="city" name="city"
                               autocomplete="city"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                               :class="addressValidation.fields.city ? ['!border-primary'] : []" />
                    </div >
                </div >
                <div >
                    <label for="zipcode" class="block text-sm font-medium text-gray-700" >PLZ</label >
                    <div class="mt-1" >
                        <input disabled v-model="order.customer.postal_code" type="text" id="zipcode" name="zipcode"
                               autocomplete="zip-code"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                               :class="addressValidation.fields.postal_code ? ['!border-primary'] : []" />
                    </div >
                </div >
                <div >
                    <label for="country" class="block text-sm font-medium text-gray-700" >Land</label >
                    <div class="mt-1" >
                        <input disabled v-model="order.customer.country" type="text" id="zipcode" name="country"
                               autocomplete="country"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
            </div >
        </div >

        <div v-show="currentTab == 'Trackingstatus'" class="text-gray-700" >
            <div class="mb-4 text-sm" >
                <span >Versandart: </span >
                <span
                    class="inline-flex items-center rounded-full px-3 py-0.5 text-sm text-black ml-2 text-gray-700 bg-slate-200" >{{
                        order.delivery_method
                    }}</span >
            </div >

            <div >
                <v-client-table ref="trackingTable" :data="this.order?.tracking_ids" :columns="trackingColumns"
                                :options="trackingOptions" >
                    <template v-slot:index="props" >
                        {{ props.index }}
                    </template >

                    <template v-slot:trackingId="props" >
                        <div class="flex items-center" >
                            <span >{{ props.row.tracking_id }}</span >
                            <a :href="`https://www.dhl.com/de-de/home/tracking/tracking-parcel.html?submit=1&tracking-id=${props.row.tracking_id}`"
                               target="_blank" >
                                <ArrowTopRightOnSquareIcon class="h-4 ml-1" />
                            </a >
                        </div >
                    </template >

                    <template v-slot:status="props" >
                        <div
                            class="inline-flex items-center rounded-full px-3 py-0.5 text-sm text-black ml-2 text-gray-700"
                            :class="[
                             {'bg-slate-200' : getTrackingStatus(props.row.tracking_status) === 'Angemeldet'
                                 || getTrackingStatus(props.row.tracking_status) === 'Unterwegs'
                                 || getTrackingStatus(props.row.tracking_status) === 'Status unbekannt'
                             },
                             {'bg-green-200' : getTrackingStatus(props.row.tracking_status) === 'Zugestellt'},
                             {'bg-red-200' : getTrackingStatus(props.row.tracking_status) === 'Zustellung nicht möglich'},
                         ]" >
                            {{ getTrackingStatus(props.row.tracking_status) }}
                        </div >
                    </template >

                    <template v-slot:research="props" >
                        <div class="flex items-center" v-if="isSent()" >
                            <a :href="`mailto:nachforschung@beckmannsys.com?subject=Nachforschungsauftrag - ${orderId} - ${props.row}&body=Allgemein%0D%0AKunde: ${order.customer.company ? order.customer.company : order.customer.first_name + ' ' + order.customer.last_name}%0D%0AAuftragsnummer: ${orderId}%0D%0ASendungsnummer: ${props.row}%0D%0A%0D%0A%0D%0AEmpfänger%0D%0AE-Mail des Empfängers: *bitte ausfüllen*%0D%0ATelefonnummer: *bitte ausfüllen*%0D%0A%0D%0A%0D%0ABankdaten für eine eventuelle Ersatzleistung%0D%0AKontoinhaber: *bitte ausfüllen*%0D%0ABank: *bitte ausfüllen*%0D%0AIBAN: *bitte ausfüllen*%0D%0ABIC: *bitte ausfüllen*%0D%0A%0D%0A%0D%0ADokumente%0D%0AEine Empfängererklärung, dass das Paket nicht angekommen ist (z.B. Screenshot der E-Mail):%0D%0ABitte als E-Mail Anhang in .jpg oder .pdf%0D%0A%0D%0AEinen Wertnachweis, d.h. Kopie der Rechnung:%0D%0ABitte als E-Mail Anhang in .jpg oder .pdf%0D%0A%0D%0A%0D%0A%0D%0ASobald der Auftrag gestellt wurde, kümmert sich der Versanddienstleister darum, den letzten Aufenthaltsort des Pakets ausfindig zu machen. Bitte beachte, dass es bis zu 4 Wochen dauern kann, bis wir weitere Informationen von DHL erhalten.`"
                               target="_blank" >
                                <QuestionMarkCircleIcon class="h-6" />
                            </a >
                        </div >
                        <div v-else >-</div >
                    </template >
                </v-client-table >
            </div >
        </div >
    </div >
</template >

<script >

import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'
import {
    ArrowTopRightOnSquareIcon,
    ExclamationTriangleIcon,
    InboxArrowDownIcon,
    QuestionMarkCircleIcon,
    XMarkIcon
} from '@heroicons/vue/24/outline'
import FormatDate from './FormatDate.vue';
import TaskModal from './TaskModal.vue';
import {ChevronDownIcon} from "@heroicons/vue/20/solid";
import {TagIcon} from "@heroicons/vue/24/solid";
import Cookies from "js-cookie";

export default {
    components: {
        XMarkIcon, TagIcon, ChevronDownIcon,
        TaskModal,
        FormatDate,
        ExclamationTriangleIcon,
        ArrowTopRightOnSquareIcon,
        InboxArrowDownIcon,
        QuestionMarkCircleIcon,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems
    },

    props: [
        "orderId",
        "preOpenTab",
    ],

    computed: {
        hasOverOrdering() {
            for (let pos of this.order?.positions) {
                if (pos.quantity > pos.available && !this.isSent()) {
                    return true;
                }
            }
            return false;
        },

        addressValidation() {
            if (!this.ready) {
                return {fields: [], valid: true};
            }
            let result = {
                fields: {},
                valid: true,
            }
            if (!(this.order.customer.first_name && this.order.customer.last_name) && !this.order.customer.company) {
                result.fields.first_name = result.fields.last_name = result.fields.company = true;
                result.valid = false;
            }
            if (!this.order.customer.address || !/\d+/.test(this.order.customer.address)) {
                result.fields.address = false;
                result.valid = false;
            }
            if (!this.order.customer.postal_code) {
                result.fields.postal_code = true;
                result.valid = false;
            }
            if (!this.order.customer.city) {
                result.fields.city = true;
                result.valid = false;
            }
            return result;
        }
    },

    data() {
        return {
            tabs: [
                "Positionen",
                "Lieferadresse",
                "Trackingstatus",
            ],
            tags: [],
            currentTab: this.preOpenTab,
            positionColumns: [
                "product",
                "name",
                "gtin",
                'sku',
                "quantity",
                "available",
            ],
            positionOptions: {
                headings: {
                    "product": "Artikel",
                    "name": "Name",
                    "gtin": "GTIN",
                    'sku': 'SKU',
                    "quantity": "Menge",
                    "available": "Verfügbar",
                },
                texts: {
                    count: '{from} bis {to} von {count} Einträgen|{count} Einträge| Ein Eintrag',
                    noResults: 'Keine Einträge vorhanden',
                    loading: 'Wird geladen',
                    filterPlaceholder: 'Suchen',
                },
                templates: {
                    "quantity": function (h, row, index) {
                        return Math.round(row["quantity"]);
                    },
                },
                perPage: 5,
            },
            trackingColumns: [
                "index",
                "trackingId",
                "status",
                "research",
            ],
            trackingOptions: {
                headings: {
                    "index": "Packstück",
                    "trackingId": "Trackingnummer",
                    "status": "DHL API Status",
                    "research": "Nachforschung",
                },
                texts: {
                    count: '{from} bis {to} von {count} Einträgen|{count} Einträge| Ein Eintrag',
                    noResults: 'Keine Einträge vorhanden',
                    loading: 'Wird geladen',
                    filterPlaceholder: 'Suchen',
                },
                perPage: 5,
            },
            order: {
                positions: [],
                customer: {},
                tracking_ids: [],
            },
            ready: false,
        };
    },

    methods: {
        getTrackingStatus(status) {
            switch (status) {
                case 'pre-transit':
                    return 'Angemeldet';
                case 'transit':
                    return 'Unterwegs';
                case 'delivered':
                    return 'Zugestellt';
                case 'failure':
                    return 'Zustellung nicht möglich';
                case 'unknown':
                    return 'Status unbekannt';
                default:
                    return '';
            }
        },

        close() {
            this.$emit('modal-close');
        },

        isSent() {
            return this.order.tracking_ids.length;
        },

        setTag(tag) {
            this.order.tag = tag.name;
            this.updateOrderTag();
        },

        unsetTag() {
            this.order.tag = null;
            this.updateOrderTag();
        },

        updateOrderTag() {
            axios.put(`/api/orders/${this.orderId}/tag`, {
                tag: this.order.tag
            })
                .then(response => {
                    this.$emit('update');
                })
                .catch(error => {
                    console.log("Error updating rma", error);
                });
        },
    },

    mounted() {
        this.tags = typeof Cookies.get('tags') === 'string' && typeof Cookies.get('tags') !== 'undefined' ? JSON.parse(Cookies.get('tags')) : [];

        axios.get('/api/orders/' + this.orderId)
            .then(response => {
                this.order = response.data.data;
                this.ready = true;
            })
            .catch(error => {
                console.log("Error fetching orders", error);
            });
    },
}
</script >
